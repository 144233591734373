import { CoreComponent } from './component.js';

export class Splash extends CoreComponent {
    static is = 'splash';

    static template = this.html`
        <feed-post compact>
            <div slot="source">
                <flux-logo></flux-logo>
                <p>A Miniflux client with thumbnail preview</p>
            </div>

            <flux-login slot="date">
                <icon-button icon="mdi:login-variant" with-label>Login</icon-button>
                <span slot="title">Login</span>
            </flux-login>

            <h3>Notable features</h3>
            <ul>
                <li>Multi-user <a class="keep-fresh" href="https://miniflux.app/" target="_blank">Miniflux</a> client (v2.0.44 or above)</li>
                <li>Thumbnail preview</li>
                <li>Automatic duplicate entries handling</li>
                <li>Broken feeds management</li>
                <li>Fullscreen mode (double tap/click on main area)</li>
            </ul>
        </feed-post>
    `;

}

Splash.define();