import { CoreComponent } from './component.js';
import { appState } from './state.js';

export class RefreshFeedButton extends CoreComponent {
    static is = 'refresh-feed-button';

    static template = this.html`
        <icon-button id="refresh" icon="mdi:refresh" with-label>Refresh</icon-button>
    `;

    firstRendered() {
        this.refreshButtonEl = this.$('#refresh');
        this.addEventListener('click', () => this.refreshFeed(this.feedid));
    }

    get feedid() {
        return this.getAttribute('feedid');
    }

    set feedid(value) {
        this.setAttribute('feedid', value);
    }

    get preventMultipleRefresh() {
        return this.hasAttribute('preventmultiplerefresh');
    }

    set preventMultipleRefresh(value) {
        this.toggleAttribute('preventmultiplerefresh', value);
    }

    get refreshStatus() {
        return this.getAttribute('refreshstatus') || 'idle';
    }

    set refreshStatus(value) {
        this.setAttribute('refreshstatus', value);
    }

    get isIdle() {
        return this.refreshStatus === 'idle';
    }

    get isProgress() {
        return this.refreshStatus === 'progress';
    }

    get isSuccess() {
        return this.refreshStatus === 'success';
    }

    get isError() {
        return this.refreshStatus === 'error';
    }

    setIdleState() {
        this.refreshButtonEl.classList.remove('brand');
        this.refreshButtonEl.classList.remove('error');
        this.refreshButtonEl.innerText = 'Refresh';
        this.refreshButtonEl.icon = 'mdi:refresh';
        this.refreshButtonEl.spinIcon = false;

        this.refreshStatus = 'idle';
        this.errorMessage = null;
        this.#updateStatus();
    }

    setProgressState() {
        this.refreshButtonEl.classList.remove('brand');
        this.refreshButtonEl.classList.remove('error');
        this.refreshButtonEl.innerText = 'Refreshing...';
        this.refreshButtonEl.icon = 'mdi:refresh';
        this.refreshButtonEl.spinIcon = true;

        this.refreshStatus = 'progress';
        this.errorMessage = null;
        this.#updateStatus();
    }

    setSuccessState() {
        this.refreshButtonEl.classList.add('brand');
        this.refreshButtonEl.classList.remove('error');
        this.refreshButtonEl.innerText = 'Success';
        this.refreshButtonEl.icon = 'mdi:done-all';
        this.refreshButtonEl.spinIcon = false;

        this.refreshStatus = 'success';
        this.errorMessage = null;
        this.#updateStatus();
    }

    setErrorState(errorMessage=null) {
        this.refreshButtonEl.classList.remove('brand');
        this.refreshButtonEl.classList.add('error');
        this.refreshButtonEl.innerText = 'Error';
        this.refreshButtonEl.icon = 'mdi:error';
        this.refreshButtonEl.spinIcon = false;

        this.refreshStatus = 'error';
        this.errorMessage = errorMessage;
        this.#updateStatus();
    }

    refreshFeed = async (feedId=null) => {
        if (!feedId) return;

        if (this.isProgress) return;

        if (this.isSuccess && this.preventMultipleRefresh) return;

        this.setProgressState();

        return await appState.refreshFeed(feedId)
            .then(() => {
                this.setSuccessState();
            })
            .catch(({ error_message }) => {
                this.setErrorState(error_message);
            });
    };

    #updateStatus() {
        this.fireEvent('statuschanged', {
            status: this.refreshStatus,
            error: this.errorMessage,
        });
    }
}

RefreshFeedButton.define();