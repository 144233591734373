import { getRelativeTimeString } from '../lib/relative-datetime.js';
import { WebComponent, css, html } from '../lib/web-component.js';
import { appTheme, surface } from './tokens.js';

export class FeedCard extends WebComponent {
    static is = 'feed-card';

    static styles = css`
        :host {
            ${surface.surface2}
            display: flex;
            flex-flow: column;
            border-radius: 3rem;
        }

        .Thumbnail {
            padding: 1rem 1rem 0;
            display: flex;
            flex-flow: column;
            gap: 1rem;
        }

        .Content {
            flex: 1;
            display: grid;
            grid-template:
                "title   " auto
                "category" 1fr
                "metadata" auto
                / 1fr;
            gap: 2rem;
            padding: 2rem;
            position: relative;
        }

        .Content-title {
            grid-area: title;
        }

        .Content-category {
            grid-area: category;
        }

        .Content-metadata {
            grid-area: metadata;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-end;
            justify-content: space-between;
        }

        .Actions {
            grid-area: actions;
        }

        :host([read]) {
            opacity: .6;
        }

        :host([compact]) {
            border-radius: 2rem;
            margin-block-start: 2rem;
        }

        :host([compact]) .Thumbnail {
            display: block;
        }

        :host([compact]) .Content {
            gap: 0;
            padding-block-start: 0;
        }

        ::slotted(img),
        ::slotted(audio) {
            inline-size: 100%;
        }

        ::slotted(img) {
            border-radius: 2rem;
        }

        ::slotted(audio) {
            border-radius: 3rem;
        }

        ::slotted(a[slot="title"]),
        ::slotted(a[slot="category"]) {
            text-decoration: none;
        }

        ::slotted(app-actions[slot="actions"]) {
            grid-area: actions;
            justify-content: space-around;
            padding: 1rem;
            border-block-start: .1rem solid ${appTheme.tinted1};
        }
    `;

    static template = html`
        <div class="Thumbnail">
            <slot></slot>
        </div>

        <div class="Content">
            <div class="Content-title">
                <slot name="title"></slot>
            </div>

            <div class="Content-category">
                <slot name="category"></slot>
            </div>
    
            <div class="Content-metadata">
                <div class="Content-source">
                    <slot name="source"></slot>
                </div>
    
                <div class="Content-date">
                    <slot name="date"></slot>
                </div>
            </div>
        </div>

        <div class="Actions">
            <slot name="actions"></slot>
        </div>
    `;

    get compact() {
        return this.hasAttribute('compact');
    }

    set compact(value) {
        this.toggleAttribute('compact', value);
    }

    get read() {
        return this.hasAttribute('read');
    }

    set read(value) {
        this.toggleAttribute('read', value);
    }
}

FeedCard.define();

export class FeedTitle extends WebComponent {
    static is = 'feed-title';

    static styles = css`
        :host {
            display: block;
        }

        .Title {
            margin: 0;
            font-size: 3rem;
            line-height: 1.2;
            font-weight: bold;
            text-align: balance;
            word-break: break-word;
            color: ${appTheme.font1};
        }
    `;

    static template = html`
        <h2 class="Title">
            <slot></slot>
        </h2>
    `;
}

FeedTitle.define();

const sharedMetadataStyles = css`
    :host {
        display: inline-block;
        font-size: 1.8rem;
    }
`;

export class FeedCategory extends WebComponent {
    static is = 'feed-category';

    static styles = [
        sharedMetadataStyles,
        css`
            :host {
                ${surface.tinted1}
                padding: .5rem 2rem;
                border-radius: 1rem;
            }
        `,
    ];
}

FeedCategory.define();

export class FeedSource extends WebComponent {
    static is = 'feed-source';

    static styles = [
        sharedMetadataStyles,
        css`
            ::slotted(*) {
                display: block:
            }

            ::slotted(a) {
                color: inherit;
                text-decoration: none;
            }

            ::slotted(a:hover) {
                text-decoration: underline;
            }
        `,
    ];
}

FeedSource.define();

export class FeedDate extends WebComponent {
    static is = 'feed-date';

    static observedAttributes = ['timestamp'];

    static styles = [
        sharedMetadataStyles,
        css`
            :host {
                color: ${appTheme.font2};
            }
        `,
    ];

    get timestamp() {
        return this.getAttribute('timestamp');
    }

    set timestamp(value) {
        return this.setAttribute('timestamp', value);
    }

    connectedCallback() {
        super.connectedCallback();

        this.updateTimestamp(this.timestamp);
    }

    updateTimestamp(timestamp=null) {
        const date = new Date(timestamp || new Date());
        this.innerText = getRelativeTimeString(date);
    }

    attributeChangedCallback(_attr, oldValue, newValue) {
        if (newValue === oldValue) return;

        this.updateTimestamp(newValue);
    }
}

FeedDate.define();