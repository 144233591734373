class ReactiveStore extends EventTarget {
    #value;

    #raf = null;

    #persistKey;
    #storage;

    constructor(initialValue, persistKey=null, storage=localStorage) {
        super();

        this.#value = initialValue;

        if (persistKey) {
            this.#persistKey = persistKey;
            this.#storage = storage;

            this.value = this.persistData || initialValue;

            this.subscribe(value => this.persistData = value);
        }
    }

    get value() {
        return this.#value;
    }

    set value(newValue) {
        if (newValue === this.#value) return;

        this.#value = newValue;

        this.#triggerUpdate();
    }

    get persistData() {
        return JSON.parse(this.#storage.getItem(this.#persistKey));
    }

    set persistData(value) {
        this.#storage.setItem(this.#persistKey, JSON.stringify(value));
    }

    subscribe(subscriber) {
        const callback = ({ detail }) => subscriber(detail);

        this.addEventListener('update', callback);

        const unsubscribe = () => this.removeEventListener('update', callback);

        this.#triggerUpdate();

        return unsubscribe;
    }

    #triggerUpdate() {
        if (this.#raf) cancelAnimationFrame(this.#raf);

        this.#raf = requestAnimationFrame(() => {
            this.dispatchEvent(new CustomEvent('update', { detail: this.#value }));
            this.#raf = null;
        });
    }
}

export const reactive = (...args) => new ReactiveStore(...args);

export const computed = (fn, ...stores) => {
    const execute = () => fn(...stores.map(store => store.value));

    const computedValue = new ReactiveStore(execute());

    stores.forEach(store => {
        store.subscribe(() => {
            computedValue.value = execute();
        });
    });

    return computedValue;
};
