import { CoreComponent } from './component.js';

export class Logo extends CoreComponent {
    static is = 'logo';

    static get styles() {
        return [
            ...super.styles,
            this.css`
                h1 {
                    font-size: 4.5rem;
                    font-weight: 300;
                    margin: 0;
                }
            `,
        ]
    }

    static template = this.html`
        <h1><span class="brand">Pocket</span>flux</h1>
    `;

}

Logo.define();