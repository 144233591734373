import { WebComponent, css, html } from '../lib/web-component.js';
import { appTheme, mediaQueries, surface } from './tokens.js';

export class MenuFolder extends WebComponent {
    static is = 'menu-folder';

    static observedAttributes = ['open'];

    static styles = css`
        :host {
            display: block;
            position: relative;
        }

        .Summary {
            color: inherit;
            display: grid;
            place-items: center;
            list-style: none;
            inline-size: 5rem;
            block-size: 5rem;
            border-radius: 50%;
            background: transparent;
            outline: none;
            cursor: pointer;
            position: absolute;
            inset-block-start: .5rem;
            inset-inline-start: 1rem;
        }

        .Summary::-webkit-details-marker {
            display:none;
        }

        .Summary:hover,
        .Summary:focus {
            ${surface.tinted1}
        }

        .Summary:active {
            filter: brightness(0.7);
        }

        [rotate-trigger],
        ::slotted([rotate-trigger]) {
            transition: transform .2s;
        }

        .Details[open] {
            ${surface.tinted2}
            border-block: .1rem solid ${appTheme.tinted1};
        }

        .Details[open] [rotate-trigger],
        .Details[open] ::slotted([rotate-trigger]) {
            transform: rotate(90deg);
        }

        :host([trigger-reverse]) .Summary {
            transform: scaleX(-1);
            inset-inline-start: auto;
            inset-inline-end: 1rem;
        }

        @media ${mediaQueries.onDesktop} {
            .Summary {
                inline-size: 4rem;
                block-size: 4rem;
                inset-block-start: .5rem;
                inset-inline-start: 1.5rem;
            }

            :host([trigger-reverse]) .Summary {
                inset-inline-start: auto;
                inset-inline-end: 1.5rem;
            }
        }

    `;

    static template = html`
        <div class="Folder">
            <slot name="folder"></slot>
        </div>

        <details class="Details">
            <summary class="Summary">
                <slot name="trigger">
                    <ui-icon rotate-trigger icon="mdi:chevron-right"></ui-icon>
                </slot>
            </summary>

            <div class="Content">
                <slot></slot>
            </div>
        </details>
    `;

    firstRendered() {
        this.open = this.hasAttribute('open');

        this.$('details').addEventListener('toggle', e => {
            this.toggleAttribute('open', e.target.open);
        });
    }

    get open() {
        return this.$('details')?.open || false;
    }

    set open(value) {
        const detailsEl = this.$('details');

        if (!detailsEl) return;

        detailsEl.open = value;
    }

    attributeChangedCallback(_attr, oldValue, newValue) {
        if (newValue === oldValue) return;

        this.open = this.hasAttribute('open');
    }
}

MenuFolder.define();

