import { WebComponent, css, html } from '../lib/web-component.js';
import { appTheme, mediaQueries, surface } from './tokens.js';

export class UIIcon extends WebComponent {
    static is = 'ui-icon';

    static get observedAttributes() {
        return ['icon'];
    }

    static get styles() {
        return css`
            :host {
                display: inline-block;
            }

            iconify-icon {
                color: inherit;
                font-size: 3rem;
                inline-size: 3rem;
                block-size: 3rem;
            }

            :host([spin-icon]) iconify-icon {
                animation: spin .5s linear infinite;
            }

            :host([spaced]) {
                padding: 1rem;
            }

            @keyframes spin {
                to {
                    transform: rotate(360deg);
                }
            }
        `;
    }

    static get template() {
        return html`
            <iconify-icon></iconify-icon>
        `;
    }

    firstRendered() {
        this.icon = this.getAttribute('icon');
    }

    get icon() {
        return this.$('iconify-icon')?.getAttribute('icon');
    }

    set icon(value) {
        const iconEl = this.$('iconify-icon');
        if (!iconEl) return;
        iconEl.setAttribute('icon', value);
    }

    get spinIcon() {
        return this.hasAttribute('spin-icon');
    }

    set spinIcon(value) {
        this.toggleAttribute('spin-icon', value);
    }

    attributeChangedCallback(_attr, oldValue, newValue) {
        if (newValue === oldValue) return;

        this.icon = newValue;
    }
}

UIIcon.define();

export class IconButton extends UIIcon {
    static is = 'icon-button';

    static get styles() {
        return [
            super.styles,
            css`
                .Button {
                    color: inherit;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    border: none;
                    padding: 1rem;
                    inline-size: 100%;
                    border-radius: 50%;
                    background: transparent;
                    font-size: 2rem;
                    outline: none;
                    cursor: pointer;
                    white-space: nowrap;
                    transition: background .1s, filter .1s;
                }

                .Button:hover,
                .Button:focus {
                    ${surface.tinted1}
                }

                .Button:active {
                    filter: brightness(0.7);
                }

                :host([with-label]) .Button,
                :host([vertical-label]) .Button {
                    border-radius: 1rem;
                }

                :host([vertical-label]) .Button {
                    flex-flow: column;
                }

                @media ${mediaQueries.touchPointer} {
                    .Button:hover {
                        background: transparent;
                    }
                }
            `,
        ];
    }

    static get template() {
        return html`
            <button class="Button">
                ${super.template.raw()}
                <slot></slot>
            </button>
        `;
    }
}

IconButton.define();

export class FormIconButton extends IconButton {
    static is = 'form-icon-button';

    static get observedAttributes() {
        return [...super.observedAttributes, 'type'];
    }

    static get formAssociated() {
        return true;
    }

    #internals = this.attachInternals();

    checkValidity() {
        return this.#internals.checkValidity();
    }

    reportValidity() {
        return this.#internals.reportValidity();
    }

    get validity() {
        return this.#internals.validity;
    }

    get validationMessage() {
        return this.#internals.validationMessage;
    }

    get type() {
        return this.getAttribute('type') || 'submit';
    }

    firstRendered() {
        super.firstRendered();

        this.#internals.role = 'button';

        this.$('button').addEventListener('click', () => {
            if (this.type === 'submit') this.#internals.form.requestSubmit();
            else this.#internals.form.reset();
        });
    }
}

FormIconButton.define();
