import { WebComponent, css, html } from '../lib/web-component.js';

export class TTSButton extends WebComponent {
    static is = 'tts-button';

    static styles = css`
        :host {
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: 1rem;
        }

        .TTS-stopIcon { display: none; }
        .TTS-startIcon { display: block; }

        :host([reading]) .TTS-stopIcon { display: block; }
        :host([reading]) .TTS-startIcon { display: none; }
    `;

    static template = html`
        <div class="TTS-startIcon">
            <slot name="start">
                <icon-button title="Read text" icon="mdi:tts"></icon-button>
            </slot>
        </div>

        <div class="TTS-stopIcon">
            <slot name="stop">
                <icon-button title="Stop reading" icon="mdi:tts-off"></icon-button>
            </slot>
        </div>
    `;

    #forcedStopSpeech = false;
    #voice = null;

    firstRendered() {
        if (!this.hasSpeakSupport()) {
            this.hidden = true;
        }
        window.speechSynthesis?.addEventListener('voiceschanged', () => {
            this.hidden = !window.speechSynthesis?.getVoices().length;
        });

        this.paragraphs = [];
        this.reading = window.speechSynthesis?.speaking;

        this.addEventListener('click', () => {
            if (window.speechSynthesis?.speaking) {
                this.stopReading();

            } else {
                const selection = this.getSelection();
                const textToRead = selection.length
                    ? selection
                    : this.paragraphs;
                this.readContent(textToRead);
            }
        });
    }

    get reading() {
        return this.hasAttribute('reading');
    }

    set reading(value) {
        this.toggleAttribute('reading', value);
    }

    get voice() {
        return this.#voice;
    }

    set voice(value) {
        if (!this.hasSpeakSupport()) return;

        const voices = window.speechSynthesis?.getVoices() || [];

        for (const voice of voices) {
            if (voice.name == value) {
                this.#voice = voice;
                return;
            }
        }

        this.#voice = null;
    }

    attributeChangedCallback(_attr, oldValue, newValue) {
        if (newValue === oldValue) return;

        this.reading = this.hasAttribute('reading');
    }

    hasSpeakSupport() {
        const support = 'speechSynthesis' in window;
        const hasVoices = !!window.speechSynthesis?.getVoices().length;
        return support && hasVoices;
    }

    getSelection() {
        const selectionText = window?.getSelection().toString();
        return selectionText ? [selectionText] : this.paragraphs;
    }

    async readContent(paragraphs=[]) {
        try {
            this.reading = true;

            for (const paragraph of paragraphs) {
                if (this.#forcedStopSpeech) {
                    // Force speech for Firefox that keeps reading 
                    // from the next line without stopping midway
                    throw new Error('Forced Stop Speech [Firefox Fix]');
                }
                await this.readText(paragraph);
            }

            this.reading = false;

        } catch (e) {
            console.error(e);
            this.stopReading();

        } finally {
            this.#forcedStopSpeech = false;
        }
    }

    readText(text) {
        return new Promise((resolve, error) => {
            let utterance = new SpeechSynthesisUtterance(text);

            if (this.#voice) utterance.voice = this.#voice;

            utterance.onend = () => {
                resolve();
            };
            utterance.onerror = (e) => {
                error(e);
            };

            window.speechSynthesis?.speak(utterance);
        });
    }

    stopReading() {
        window.speechSynthesis?.cancel();
        this.reading = false;
        this.#forcedStopSpeech = true;
    }
}

TTSButton.define();

class TTSSelect extends TTSButton {
    static is = 'tts-select';

    static styles = css``;

    static template = html`<slot></slot>`;

    #selectEl;

    firstRendered() {
        this.#selectEl = this.querySelector('select');

        if (!this.hasSpeakSupport()) {
            this.hidden = true;
        }

        window.speechSynthesis?.addEventListener('voiceschanged', () => {
            this.popolateVoiceList();
        });

        this.popolateVoiceList();
    }

    popolateVoiceList() {
        if (!this.#selectEl) return;

        const voices = window.speechSynthesis?.getVoices() || [];

        this.hidden = !voices.length;

        const optionEls = voices.map((voice) => {
            const optionEl = document.createElement("option");
            optionEl.textContent = `${voice.name} (${voice.lang})`;

            if (voice.default) {
                optionEl.textContent += " — DEFAULT";
            }

            optionEl.setAttribute("data-lang", voice.lang);
            optionEl.setAttribute("data-name", voice.name);
            optionEl.value = voice.name;

            return optionEl;
        });

        this.#selectEl.replaceChildren(...optionEls);
    }
}

TTSSelect.define();