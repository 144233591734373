import { CoreComponent } from './component.js';
import { accountState, feedState } from './state.js';

export class NewFeed extends CoreComponent{
    static is = 'new-feed';

    static template = this.html`
        <dialog-panel id="dialog">
            <menu-item slot="trigger">
                <ui-icon slot="icon" icon="mdi:add"></ui-icon>
                <span>New Feed</span>
            </menu-item>

            <span slot="title">New Feed</span>

            <form id="new-feed" method="dialog">
                <label>
                    <span>
                        <ui-icon icon="mdi:internet"></ui-icon>
                        <span>
                            URL
                        </span>
                    </span>
                    <input type="url" name="feed_url" placeholder="https://domain.tld/" spellcheck="false" required autofocus />
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:label"></ui-icon>
                        <span>
                            Category
                        </span>
                    </span>
                    <select name="category_id" required></select>
                </label>

                <details>
                    <summary>Advanced Options</summary>

                    <label>
                        <span>
                            <ui-icon icon="mdi:world-wide-web"></ui-icon>
                            <span>
                                Fetch original content
                            </span>
                        </span>
                        <select name="crawler">
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:user-badge"></ui-icon>
                            <span>
                                Override Default User Agent
                            </span>
                        </span>
                        <input type="text" name="user_agent" placeholder="Mozilla/5.0 (compatible; Miniflux/2.0.44; +https://miniflux.app)" />
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:user-circle"></ui-icon>
                            <span>
                                Feed Username
                            </span>
                        </span>
                        <input type="text" name="username" placeholder="Username" />
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:password"></ui-icon>
                            <span>
                                Feed Password
                            </span>
                        </span>
                        <input type="text" name="password" placeholder="Password" />
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:air-filter"></ui-icon>
                            <span>
                                Scraper Rules
                            </span>
                            <a class="push" href="https://miniflux.app/docs/rules.html#scraper-rules" target="_blank">
                                <ui-icon icon="mdi:open-in-new"></ui-icon>
                            </a>
                        </span>
                        <input type="text" name="scraper_rules" placeholder="List of scraper rules" />
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:receipt-text-edit"></ui-icon>
                            <span>
                                Rewrite Rules
                            </span>
                            <a class="push" href="https://miniflux.app/docs/rules.html#rewrite-rules" target="_blank">
                                <ui-icon icon="mdi:open-in-new"></ui-icon>
                            </a>
                        </span>
                        <input type="text" name="rewrite_rules" placeholder="List of rewrite rules" />
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:stop-alert"></ui-icon>
                            <span>
                                Block Rules
                            </span>
                            <a class="push" href="https://miniflux.app/docs/rules.html#filtering-rules" target="_blank">
                                <ui-icon icon="mdi:open-in-new"></ui-icon>
                            </a>
                        </span>
                        <input type="text" name="blocklist_rules" placeholder="Ex: (?i)miniflux" />
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:check-decagram"></ui-icon>
                            <span>
                                Keep Rules
                            </span>
                            <a class="push" href="https://miniflux.app/docs/rules.html#filtering-rules" target="_blank">
                                <ui-icon icon="mdi:open-in-new"></ui-icon>
                            </a>
                        </span>
                        <input type="text" name="keeplist_rules" placeholder="Ex: (?i)miniflux" />
                    </label>
                </details>

                <input type="submit" hidden />

                <div class="footer">
                    <form-icon-button id="find-feed" icon="mdi:search" with-label>Find a feed</form-icon-button>
                </div>

                <div id="discover-feeds" hidden></div>
            </form>
        </dialog-panel>
    `;

    firstRendered() {
        this.formEl = this.$('#new-feed');
        this.discoverFeedsEl = this.$('#discover-feeds');
        this.findFeedEl = this.$('#find-feed');

        this.formEl.addEventListener('submit', this.onSubmit);
        this.addEventListener('feed-added', this.feedAdded);

        this.addEventListener('dialog-close', () => {
            this.resetDialog();
        });

        this.categoriesUnsubscribe = feedState.categories.subscribe(
            this.updateCategories
        );

        this.handleShareTarget();
    }

    disconnectedCallback() {
        this.categoriesUnsubscribe();
    }

    handleShareTarget() {
        if (!accountState.activeToken.value) return;

        const url = new URL(document.location);

        let sharedURL = null;

        try {
            sharedURL = new URL(url.searchParams.get('url'));

        } catch {
            try {
                sharedURL = new URL(url.searchParams.get('text'));

            } catch {
                try {
                    sharedURL = new URL(url.searchParams.get('title'));

                } catch {
                    sharedURL = null;
                }
            }
        }

        if (!sharedURL) return;

        this.$('#dialog').open = true;

        this.$('[name="feed_url"]').value = `${sharedURL}`;
    }

    updateCategories = categories => {
        const optionEls = categories.map(
            category => {
                const optionEl = document.createElement('option');
                optionEl.value = category.value;
                optionEl.innerText = category.label;
                return optionEl;
            }
        );
        this.$('[name="category_id"]').replaceChildren(...optionEls);
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.findFeedEl.icon = 'mdi:refresh';
        this.findFeedEl.spinIcon = true;

        const formData = new FormData(e.target);

        const discoverURL = formData.get('feed_url');
        const discoverData = {
            username: formData.get('username'),
            password: formData.get('password'),
            user_agent: formData.get('user_agent'),
        };

        const data = {
            feed_url: formData.get('feed_url'),
            category_id: parseInt(formData.get('category_id')),
            crawler: JSON.parse(formData.get('crawler')),
            username: formData.get('username'),
            password: formData.get('password'),
            user_agent: formData.get('user_agent'),
            scraper_rules: formData.get('scraper_rules'),
            rewrite_rules: formData.get('rewrite_rules'),
            blocklist_rules: formData.get('blocklist_rules'),
            keeplist_rules: formData.get('keeplist_rules'),
        };

        this.discoverFeedsEl.replaceChildren();

        feedState.discoverFeeds(discoverURL, discoverData)
            .then(feeds => {
                this.updateDiscoverFeeds(feeds, data);
            }).finally(() => {
                this.findFeedEl.icon = 'mdi:search';
                this.findFeedEl.spinIcon = false;
            });
    };

    updateDiscoverFeeds = (feeds, data) => {
        this.discoverFeedsEl.hidden = !feeds.length;

        const feedEls = feeds.map(feed => {
            const feedEl = document.createElement('flux-discover-feed');

            feedEl.title = feed.title;
            feedEl.url = feed.url;
            feedEl.type = feed.type || '';
            feedEl.error = feed.error || '';
            feedEl.fatal = !!feed.error;
            feedEl.data = data;

            return feedEl;
        });
        this.discoverFeedsEl.replaceChildren(...feedEls);
    };

    feedAdded = () => {
        this.resetDialog();
        this.$('#dialog').close();
        this.fireEvent('refresh-feeds');
    };

    resetDialog() {
        this.$('details').toggleAttribute('open', false);
        this.formEl.reset();
        this.discoverFeedsEl.replaceChildren();
    }
}

NewFeed.define();