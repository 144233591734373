import { CoreComponent } from './component.js';
import { accountState, listState } from './state.js';

export class ListActions extends CoreComponent {
    static is = 'list-actions';

    static template = this.html`
        <app-actions>
            <form id="search" method="dialog">
                <toggle-button id="search-toggle" reverse>
                    <icon-button slot="open" type="reset" title="Search" icon="mdi:search"></icon-button>
                    <form-icon-button slot="close" type="reset" title="Close Search"
                        icon="mdi:close-circle"></form-icon-button>
                    <input type="search" name="search" results="5" placeholder="Search" />
                </toggle-button>
            </form>

            <dialog-panel id="dialog">
                <icon-button id="filter-icon" slot="trigger" title="Filter options" icon="mdi:filter-variant"></icon-button>

                <span slot="title">Filter options</span>

                <form id="filters" method="dialog">

                    <label>
                        <span>
                            <ui-icon icon="mdi:eye"></ui-icon>
                            <span>
                                Include read
                            </span>
                        </span>
                        <select name="include_read">
                            <option value="false">Hide</option>
                            <option value="true">Show</option>
                        </select>
                    </label>

                    <label>
                        <span>
                            <ui-icon icon="mdi:star"></ui-icon>
                            <span>
                                Only starred
                            </span>
                        </span>
                        <select name="only_starred">
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </label>

                    <div class="footer">
                        <form-icon-button icon="mdi:done" with-label>Apply</form-icon-button>
                        <form-icon-button type="reset" icon="mdi:refresh" with-label>Reset</form-icon-button>
                    </div>
                </form>

            </dialog-panel>
        </app-actions>
    `;

    firstRendered() {
        this.searchEl = this.$('#search');
        this.filtersEl = this.$('#filters');

        this.searchEl.addEventListener('submit', this.onSearchSubmit);
        this.searchEl.addEventListener('reset', this.onSearchReset);

        this.filtersEl.addEventListener('submit', this.onFiltersSubmit);
        this.filtersEl.addEventListener('reset', this.onFiltersReset);

        this.filtersAppliedUnsubscribe = listState.filtersApplied.subscribe(
            filtersApplied => {
                this.$('#filter-icon').icon = filtersApplied
                    ? 'mdi:filter-variant-plus'
                    : 'mdi:filter-variant';
            }
        );
    }

    disconnectedCallback() {
        this.filtersAppliedUnsubscribe();
        listState.reset();
    }

    onSearchSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const search = formData.get('search').trim();

        listState.updateSearch(search);
    };

    onSearchReset = () => {
        listState.updateSearch('');
    };

    onFiltersSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const includeRead = JSON.parse(formData.get('include_read'));
        const onlyStarred = JSON.parse(formData.get('only_starred'));

        listState.updateIncludeRead(includeRead);
        listState.updateOnlyStarred(onlyStarred);

        this.$('#dialog').close();
    };

    onFiltersReset = () => {
        listState.updateIncludeRead(false);
        listState.updateOnlyStarred(false);

        this.$('#dialog').close();
    };
}

ListActions.define();