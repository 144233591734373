import { WebComponent, css, html } from '../lib/web-component.js';
import { postStyles } from './styles.js';

export class FeedPost extends WebComponent {
    static is = 'feed-post';

    static styles = [
        postStyles,
        css`
        :host {
            display: block;
            padding-inline: 2rem;
            inline-size: min(120rem, 100%);
            margin-inline: auto;
        }

        article {
            overflow-wrap: break-word;
        }

        header {
            padding-block-end: 4rem;
        }

        :host([compact]) header {
            padding-block-end: 0;
        }

        ::slotted(h1) {
            font-size: 3rem;
        }
    `];

    static template = html`
        <article>
            <header>
                <slot name="media"></slot>

                <slot name="thumbnail"></slot>

                <slot name="heading"></slot>

                <app-header compact>
                    <div slot="title">
                        <slot name="source"></slot>
                    </div>

                    <div>
                        <slot name="category"></slot>
                    </div>
                </app-header>

                <app-header compact>
                    <div slot="title">
                        <slot name="date"></slot>
                    </div>

                    <div>
                        <slot name="actions"></slot>
                    </div>
                </app-header>
            </header>

            <slot></slot>
        </article>
    `;
}

FeedPost.define();

