import { CoreComponent } from './component.js';
import { pwaState } from './state.js';

let defferedInstallPrompt = null;

window.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('beforeinstallprompt', async (e) => {
        e.preventDefault();
        defferedInstallPrompt = e;
        pwaState.showPrompt.value = true;
    });
});

const installApp = async () => {
    if (defferedInstallPrompt) {
        defferedInstallPrompt.prompt();

        const { outcome } = await defferedInstallPrompt.userChoice;

        // Act on the user's choice
        if (outcome === 'accepted') {
            console.log('User accepted the install prompt.');
        } else if (outcome === 'dismissed') {
            console.log('User dismissed the install prompt');
        }

        // The defferedInstallPrompt can only be used once.
        defferedInstallPrompt = null;
        pwaState.showPrompt.value = false;
    }
};

class InstallTrigger extends CoreComponent {
    static is = 'install-trigger';

    firstRendered() {
        if (defferedInstallPrompt === null) this.hidden = true;

        this.showPromptUnsubscribe = pwaState.showPrompt.subscribe(
            show => this.hidden = !show
        );

        this.addEventListener('click', this.onClick);
    }

    disconnectedCallback() {
        this.showPromptUnsubscribe();
    }

    onClick = () => {
        installApp();
    };
}

InstallTrigger.define();