import { WebComponent, css, html } from '../lib/web-component.js';
import { mediaQueries, surface } from './tokens.js';

export class DialogPanel extends WebComponent {
    static is = 'dialog-panel';

    static observedAttributes = ['open'];

    static styles = css`
        :host {
            display: block;
        }

        .Dialog {
            ${surface.surface4}
            inline-size: min(50rem, calc(100% - 8rem));
            border: none;
            border-radius: 3rem;
            padding: 0 2rem 2rem;
            box-shadow: 0 1rem 2rem 1rem #0005;
        }

        .Dialog:not([open]) {
            display: none;
        }

        .Dialog::backdrop {
            background: #0003;
            backdrop-filter: blur(.5rem);
            transition: backdrop-filter .5s;
        }

        .Dialog-header {
            ${surface.surface4}
            position: sticky;
            inset-block-start: 0;
            padding-block-end: 1rem;
            transform: translate3d(0, 0, 0);
            z-index: 10;
        }

        .Dialog-content {
            margin-block-start: 1rem;
            font-size: 2rem;
        }

        .Dialog-footer {
            display: flex;
            flex-flow: row-reverse wrap;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
        }

        ::slotted(feed-card) {
            ${surface.tinted2}
        }

        @media ${mediaQueries.onMobile} {
            .Dialog {
                margin-block-end: 2rem;
            }
        }
    `;

    static template = html`
        <div id="trigger">
            <slot name="trigger"></slot>
        </div>

        <dialog class="Dialog">
            <div class="Dialog-header">
                <app-header>
                    <h2 slot="title">
                        <slot name="title"></slot>
                    </h2>

                    <app-actions id="close">
                        <slot name="close">
                            <icon-button icon="mdi:close"></icon-button>
                        </slot>
                    </app-actions>
                </app-header>

                <slot name="header"></slot>
            </div>

            <div class="Dialog-content">
                <slot></slot>
            </div>

            <div class="Dialog-footer">
                <slot name="footer"></slot>
            </div>
        </dialog>
    `;

    firstRendered() {
        this.open = this.hasAttribute('open');

        this.$('dialog').addEventListener('close', () => {
            this.toggleAttribute('open', false);
        });

        this.$('#trigger').addEventListener('click', () => {
            this.open = true;
        });

        this.$('#close').addEventListener('click', () => {
            this.open = false;
        });
    }

    get open() {
        return this.$('dialog')?.open || false;
    }

    set open(value) {
        const dialogEl = this.$('dialog');

        if (!dialogEl) return;

        if (value) {
            dialogEl.showModal();
            this.dispatchEvent(new CustomEvent('dialog-open', { bubbles: true, composed: true }));
        } else {
            dialogEl.close();
            this.dispatchEvent(new CustomEvent('dialog-close', { bubbles: true, composed: true }));
        }

        this.toggleAttribute('open', value);
    }

    showModal() {
        this.open = true;
    }

    close() {
        this.open = false;
    }

    attributeChangedCallback(_attr, oldValue, newValue) {
        if (newValue === oldValue) return;

        this.open = this.hasAttribute('open');
    }
}

DialogPanel.define();
