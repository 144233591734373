export const css = (...args) => {
    const styles = new CSSStyleSheet();
    styles.replaceSync(String.raw(...args));
    return styles;
};

export const html = (...args) => {
    const templateEl = document.createElement('template');
    const raw = String.raw(...args);
    templateEl.innerHTML = raw;
    return {
        raw() { return raw; },
        get() { return templateEl; },
        clone() { return templateEl.content.cloneNode(true); },
    };
};

export class WebComponent extends HTMLElement {
    static define(tag = null) {
        customElements.define(tag || this.is, this);
    }

    shadowRoot = this.attachShadow({ mode: "open" });

    static get styles() {
        return css`
            :host {
                display: block;
            }

            [hidden],
            :host([hidden]) {
                display: none !important;
            }
        `;
    }

    static get template() {
        return html`
            <slot></slot>
        `;
    }

    $(selector) {
        return this.shadowRoot.querySelector(selector);
    }

    $$(selector) {
        return this.shadowRoot.querySelectorAll(selector);
    }

    connectedCallback() {
        // Add the shared styles
        this.shadowRoot.adoptedStyleSheets = (
            Array.isArray(this.constructor.styles)
                ? [...this.constructor.styles]
                : [this.constructor.styles]
        );

        // Render initial template
        this.shadowRoot.replaceChildren(this.constructor.template.clone());

        this.firstRendered();
    }

    firstRendered() {}

    disconnectedCallback() {}

    attributeChangedCallback() {}
}
