import { CoreComponent } from './component.js';
import { settingsState, viewState } from './state.js';

export class Header extends CoreComponent {
    static is = 'header';

    static template = this.html`
        <app-header>
            <h2 id="title" slot="title"></h2>

            <app-actions>
                <ui-icon id="offline" class="disabled error" spaced icon="mdi:offline"></ui-icon>
                <div id="actions"></div>
            </app-actions>
        </app-header>
    `;

    firstRendered() {
        this.titleEl = this.$('#title');
        this.actionsEl = this.$('#actions');

        this.titleUnregister = viewState.currentViewTitle.subscribe(
            title => this.titleEl.innerText = title
        );

        this.viewTypeUnregister = viewState.viewType.subscribe(
            this.updateViewType
        );

        this.onlineUnsubscribe = settingsState.online.subscribe(
            online => this.$('#offline').hidden = online
        );
    }

    disconnectedCallback() {
        this.titleUnregister();
        this.viewTypeUnregister();
    }

    updateViewType = (type) => {
        const tagEl = document.createElement(`flux-${type}-actions`);
        this.actionsEl.replaceChildren(tagEl);
    };
}

Header.define();