import { CoreComponent } from './component.js';
import { appState, settingsState } from './state.js';

export class Setting extends CoreComponent{
    static is = 'settings';

    static template = this.html`
        <dialog-panel id="dialog">
            <menu-item slot="trigger">
                <ui-icon slot="icon" icon="mdi:settings"></ui-icon>
                <span>Settings</span>
            </menu-item>

            <span slot="title">Settings</span>

            <form id="settings" method="dialog">
                <label>
                    <span>
                        <ui-icon icon="mdi:palette"></ui-icon>
                        <span>
                            Theme
                        </span>
                    </span>
                    <select name="theme">
                        <option value="auto">System</option>
                        <option value="light">Light</option>
                        <option value="dark">Dark</option>
                    </select>
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:page-layout-header-footer"></ui-icon>
                        <span>
                            Header position on mobile
                        </span>
                    </span>
                    <select name="bottom_bar">
                        <option value="true">Below</option>
                        <option value="false">Above</option>
                    </select>
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:folder-open"></ui-icon>
                        <span>
                            Auto open folder in menu
                        </span>
                    </span>
                    <select name="auto_open_folder">
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                    </select>
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:done-all"></ui-icon>
                        <span>
                            Auto mark opened entries as read
                        </span>
                    </span>
                    <select name="auto_mark_entries_as_read">
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                    </select>
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:eye-off"></ui-icon>
                        <span>
                            Hide read feeds in menu
                        </span>
                    </span>
                    <select name="hide_read_feeds_in_menu">
                        <option value="false">Disabled</option>
                        <option value="true">Enabled</option>
                    </select>
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:content-duplicate"></ui-icon>
                        <span>
                            Mark duplicate entries as read
                        </span>
                    </span>
                    <select name="mark_duplicate_entries">
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                    </select>
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:rss-off"></ui-icon>
                        <span>
                            Show broken feeds
                        </span>
                    </span>
                    <select name="show_broken_feeds">
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                    </select>
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:image"></ui-icon>
                        <span>
                            Show thumbnail
                        </span>
                    </span>
                    <select name="show_thumbnail">
                        <option value="true">Enabled</option>
                        <option value="false">Disabled</option>
                    </select>
                </label>

                <tts-select>
                    <label>
                        <span>
                            <ui-icon icon="mdi:tts"></ui-icon>
                            <span>
                                Text-to-speech Voice
                            </span>
                        </span>
                        <select name="tts_voice"></select>
                    </label>
                </tts-select>

                <div class="footer">
                    <form-icon-button icon="mdi:content-save" with-label>Save</form-icon-button>
                </div>
            </form>
        </dialog-panel>
    `;

    firstRendered() {
        this.formEl = this.$('#settings');

        this.formEl.addEventListener('submit', this.onSubmit);

        this.unsubscribe = settingsState.settings.subscribe(
            this.updateSettings
        );
    }

    onSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData(e.target);

        const data = {
            theme: formData.get('theme'),
            auto_mark_entries_as_read: JSON.parse(formData.get('auto_mark_entries_as_read')),
            hide_read_feeds_in_menu: JSON.parse(formData.get('hide_read_feeds_in_menu')),
            bottom_bar: JSON.parse(formData.get('bottom_bar')),
            auto_open_folder: JSON.parse(formData.get('auto_open_folder')),
            mark_duplicate_entries: JSON.parse(formData.get('mark_duplicate_entries')),
            show_broken_feeds: JSON.parse(formData.get('show_broken_feeds')),
            show_thumbnail: JSON.parse(formData.get('show_thumbnail')),
            tts_voice: formData.get('tts_voice'),
        };

        appState.updateSettings(data);

        this.$('#dialog').close();
    };

    updateSettings = (settings) => {
        if (!settings.token) {
            this.hidden = true;
            return;
        }

        this.hidden = false;

        this.$(`[name="theme"]`).value = settings.theme;
        this.$(`[name="auto_mark_entries_as_read"]`).value = settings.auto_mark_entries_as_read.toString();
        this.$(`[name="hide_read_feeds_in_menu"]`).value = settings.hide_read_feeds_in_menu.toString();
        this.$(`[name="bottom_bar"]`).value = settings.bottom_bar.toString();
        this.$(`[name="auto_open_folder"]`).value = settings.auto_open_folder.toString();
        this.$(`[name="mark_duplicate_entries"]`).value = settings.mark_duplicate_entries.toString();
        this.$(`[name="show_broken_feeds"]`).value = settings.show_broken_feeds.toString();
        this.$(`[name="show_thumbnail"]`).value = settings.show_thumbnail.toString();

        // Allow tts-select component to be populated first
        setTimeout(() => {
            this.$(`[name="tts_voice"]`).value = settings.tts_voice;
        }, 100);
    };
}

Setting.define();