import { CoreComponent } from './component.js';
import { feedState } from './state.js';

export class DiscoverFeed extends CoreComponent {
    static is = 'discover-feed';

    static template = this.html`
        <feed-card compact>
            <app-header slot="title" compact>
                <feed-title class="title" slot="title">TITLE</feed-title>
                <app-actions>
                    <icon-button id="add-feed" icon="mdi:add" with-label>Add feed</icon-button>
                </app-actions>
            </app-header>
            <feed-source slot="category">
                <span class="type">type</span>
                <a class="url">url</a>
            </feed-source>
            <feed-source slot="date">
                <span class="error"></span>
            </feed-source>
        </feed-card>
    `;

    #data = {};

    get title() {
        return this.getAttribute('title');
    }

    set title(value) {
        this.setAttribute('title', value);
        this.updateContent();
    }

    get url() {
        return this.getAttribute('url');
    }

    set url(value) {
        this.setAttribute('url', value);
        this.updateContent();
    }

    get type() {
        return this.getAttribute('type');
    }

    set type(value) {
        this.setAttribute('type', value);
        this.updateContent();
    }

    get error() {
        return this.getAttribute('error') || '';
    }

    set error(value) {
        this.setAttribute('error', value);
        this.updateContent();
    }

    get fatal() {
        return this.hasAttribute('fatal');
    }

    set fatal(value) {
        this.toggleAttribute('fatal', value);
        this.updateContent();
    }

    get data() {
        return this.#data;
    }

    set data(value) {
        this.#data = value;
    }

    updateContent() {
        this.$$('.title').forEach(el => el.innerText = this.title);
        this.$$('.url').forEach(el => {
            el.href = this.url;
            el.innerText = this.url;
        });
        this.$$('.type').forEach(el => {
            el.innerText = this.type ? `(${this.type}):` : '';
        });
        this.$$('.error').forEach(el => el.innerText = this.error);
        this.$$('#add-feed').forEach(el => el.hidden = this.fatal);
    }

    firstRendered() {
        this.updateContent();

        this.addFeedButtonEl = this.$('#add-feed');
        this.addFeedButtonEl.addEventListener('click', () => this.addFeed());
    }

    async addFeed() {
        if (this.fatal) return;

        this.addFeedButtonEl.icon = 'mdi:refresh';
        this.addFeedButtonEl.spinIcon = true;

        try {
            await feedState.addFeed(this.url, this.data);
            this.fireEvent('feed-added');

        } catch (err) {
            this.error = err;
        } finally {
            this.addFeedButtonEl.icon = 'mdi:add';
            this.addFeedButtonEl.spinIcon = false;
        }
    }
}

DiscoverFeed.define();