export const callAPI = async (method, { url, token }, path, queryParams={}, body=null) => {
    const apiURL = `${url}/v1${path}?${new URLSearchParams(queryParams)}`;

    const options = {
        headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': token,
        },
        method,
        body: body ? JSON.stringify(body) : null,
    };

    const res = await fetch(apiURL, options);

    if (!res.ok) throw await res.json();

    if (res.status === 204) return res;

    return await res.json();
};
