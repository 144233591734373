import { CoreComponent } from './component.js';
import { appState, settingsState, viewState } from './state.js';

export class Shell extends CoreComponent {
    static is = 'shell';

    static template = this.html`
        <app-layout id="layout">
            <flux-drawer id="drawer" slot="drawer"></flux-drawer>

            <flux-header slot="header"></flux-header>

            <div id="main"></div>
        </app-layout>
    `;

    #pageTitle = document.title;

    firstRendered() {
        this.layoutEl = this.$('#layout');
        this.drawerEl = this.$('#drawer');
        this.mainEl = this.$('#main');

        this.themeUnsubscribe = settingsState.theme.subscribe(
            theme => document.body.setAttribute('color-scheme', theme)
        );

        this.bottomBarUnsubscribe = settingsState.bottomBar.subscribe(
            bottomBar => this.layoutEl.bottomBar = bottomBar
        );

        this.viewTypeUnsubscribe = viewState.viewType.subscribe(
            this.updateViewType
        );

        this.currentEntryIdUnsubscribe = viewState.currentEntryId.subscribe(
            id => this.viewEl && (this.viewEl.entryId = id)
        );

        this.currentViewTitleUnsubscribe = viewState.currentViewTitle.subscribe(
            title => document.title = `${title} - ${this.#pageTitle}`
        );

        this.themeColorUnsubscribe = settingsState.themeColor.subscribe(
            theme => theme && (document.querySelector('meta[name="theme-color"]').content = theme)
        );

        this.addEventListener('entry-read-status-changed', (e) => {
            this.drawerEl.updateCounter(e.detail);
        });

        window.addEventListener('hashchange', this.onHashChanged);

        window.addEventListener("online", this.onNetworkChange);
        window.addEventListener("offline", this.onNetworkChange);

        appState.refreshSettings();

        this.onHashChanged();
    }

    onHashChanged = () => {
        const path = location.hash.replace('#', '');

        appState.handleRoute(path);

        this.layoutEl.open = false;
    };

    onNetworkChange = () => {
        settingsState.online.value = navigator.onLine;
    };

    disconnectedCallback() {
        window.removeEventListener('hashchange', this.hashChanged);
        window.removeEventListener("online", this.onNetworkChange);
        window.removeEventListener("offline", this.onNetworkChange);

        this.themeUnsubscribe();
        this.bottomBarUnsubscribe();
        this.viewTypeUnsubscribe();
        this.currentEntryIdUnsubscribe();
        this.currentViewTitleUnsubscribe();
    }

    updateViewType = (type) => {
        this.viewEl = document.createElement(`flux-${type}`);

        if (type === 'POST') {
            const entryId = viewState.currentEntryId.value;
            if (entryId) this.viewEl.entryId = entryId;
        }

        this.mainEl.replaceChildren(this.viewEl);
    };
}

Shell.define();