import { CoreComponent } from './component.js';

export class BrokenFeed extends CoreComponent {
    static is = 'broken-feed';

    static template = this.html`
        <feed-card compact>
            <app-header slot="title" compact>
                <feed-title class="title" slot="title"></feed-title>
                <app-actions>
                    <flux-refresh-feed-button id="refresh" preventmultiplerefresh></flux-refresh-feed-button>
                </app-actions>
            </app-header>
            <feed-source slot="source">
                <div class="source"></div>
            </feed-source>
            <feed-date class="date" slot="date"></feed-date>
        </feed-card>
    `;

    get feedid() {
        return this.getAttribute('feedid');
    }

    set feedid(value) {
        this.setAttribute('feedid', value);
    }

    get title() {
        return this.getAttribute('title');
    }

    set title(value) {
        this.setAttribute('title', value);
        this.updateContent();
    }

    get source() {
        return this.getAttribute('source');
    }

    set source(value) {
        this.setAttribute('source', value);
        this.updateContent();
    }

    get date() {
        return this.getAttribute('date');
    }

    set date(value) {
        this.setAttribute('date', value);
        this.updateContent();
    }

    get refreshStatus() {
        return this.getAttribute('refreshstatus') || 'idle';
    }

    set refreshStatus(value) {
        this.setAttribute('refreshstatus', value);
    }

    updateContent() {
        this.$$('.title').forEach(el => el.innerText = this.title);
        this.$$('.source').forEach(el => el.innerText = this.source);
        this.$$('.date').forEach(el => el.setAttribute('timestamp', this.date));
    }

    firstRendered() {
        this.updateContent();

        this.refreshButtonEl = this.$('#refresh');
        this.refreshButtonEl.addEventListener('click', this.refreshFeed);
        this.refreshButtonEl.addEventListener('statuschanged', this.statusChanged);
    }

    refreshFeed = async () => {
        return await this.refreshButtonEl.refreshFeed(this.feedid);
    };

    statusChanged = (e) => {
        switch (e.detail.status) {
            case 'error':
                this.source = e.detail.error;
                // Fallthrough to set property

            default:
                this.refreshStatus = e.detail.status;
        }
    };
}

BrokenFeed.define();