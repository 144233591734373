import { WebComponent, css, html } from '../lib/web-component.js';
import { surface } from './tokens.js';

export class FeedList extends WebComponent {
    static is = 'feed-list';

    static styles = css`
        :host {
            display: flex;
            flex-flow: column;
            gap: 2rem;
            padding-inline: 2rem;
        }

        ::slotted(.grid),
        .Grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
            gap: 2rem;
        }

        .Pagination {
            display: grid;
            place-items: center;
            padding-block: 4rem;
            gap: 4rem;
        }

        ::slotted(icon-button[slot="pagination"]) {
            ${surface.tinted1}
        }

        ::slotted(icon-button[spin-icon][slot="pagination"]) {
            border-radius: 50%;
        }

        ::slotted(icon-button[with-label][slot="pagination"]),
        ::slotted(icon-button[vertical-label][slot="pagination"]) {
            border-radius: 1rem;
        }

        ::slotted(.grid) {
            grid-column: 1 / -1;
        }
    `;

    static template = html`
        <slot name="filters"></slot>

        <div class="Grid">
            <slot></slot>
        </div>

        <div class="Pagination">
            <slot name="pagination"></slot>
        </div>
    `;
}

FeedList.define();