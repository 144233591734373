const isString = str => (
    typeof str === 'string' || str instanceof String
);

const getScheme = ({ protocol }) => {
    const scheme = protocol.replace(':', '');

    return scheme;
};

const getNetloc = ({ username, password, host }) => {
    let credentials = '';

    if (username) {
        credentials = username;
    }

    if (password) {
        credentials = `${username}:${password}`;
    }

    return credentials ? `${credentials}@${host}` : host;
};

const getQueryParams = ({searchParams}) => {
    const queryParams = {};

    for (const [key, value] of searchParams) {
        queryParams[key] = value;
    }

    return queryParams;
};

export const unpackUrl = (url) => {
    const URLClass = URL || window.URL;

    const urlObj = new URLClass(url);

    const unpackedUrl = {
        scheme: getScheme(urlObj),
        netloc: getNetloc(urlObj),
        host: urlObj.hostname,
        username: urlObj.username,
        password: urlObj.password,
        port: urlObj.port,
        path: urlObj.pathname,
        path_parts: urlObj.pathname.split('/').filter(i => i),
        query: urlObj.search,
        query_params: getQueryParams(urlObj),
        fragment: urlObj.hash,
        full_url: urlObj.href,
    };

    return unpackedUrl;
};

export const transformUrl = (rules, urlObj) => {
    const transformedUrl = jsonLogic.apply(rules, urlObj);

    return transformedUrl;
};

export const checkUrl = (rules, urlObj) => {
    const checkedUrl = transformUrl(rules, urlObj);

    return !!checkedUrl;
};

