import { CoreComponent } from './component.js';
import { feedState } from './state.js';

export class BrokenFeedsManager extends CoreComponent {
    static is = 'broken-feeds-manager';

    static template = this.html`
        <dialog-panel id="dialog">
            <menu-item slot="trigger">
                <ui-icon slot="icon" icon="mdi:rss-off"></ui-icon>
                <span>Broken feeds</span>
                <span id="counter" slot="counter">0</span>
            </menu-item>

            <span slot="title">Broken feeds</span>

            <app-actions slot="header">
                <icon-button id="refresh-all" icon="mdi:refresh" with-label>Refresh all</icon-button>

                <select name="limit">
                    <option value="1">First 1</option>
                    <option value="5">First 5</option>
                    <option value="10">First 10</option>
                    <option value="20">First 20</option>
                    <option value="50">First 50</option>
                    <option value="100">First 100</option>
                    <option value="999">Everything</option>
                </select>

                <icon-button id="auto-refresh" icon="mdi:restart"></icon-button>
            </app-actions>

            <div id="feeds"></div>
        </dialog-panel>
    `;

    firstRendered() {
        this.refreshAllEl = this.$('#refresh-all');
        this.autoRefreshEl = this.$('#auto-refresh');

        this.brokenFeedsUnsubscribe = feedState.brokenFeeds.subscribe(
            this.updateBrokenFeeds
        );

        this.brokenFeedsCountUnsubscribe = feedState.brokenFeedsCount.subscribe(
            count => this.$('#counter').innerText = count
        );

        this.addEventListener('dialog-close', () => {
            this.clearAutoRefresh();
            this.fireEvent('refresh-feeds');
        });

        this.refreshAllEl.addEventListener('click', this.refreshAll);
        this.autoRefreshEl.addEventListener('click', this.toggleAutoRefresh);
    }

    disconnectedCallback() {
        this.brokenFeedsUnsubscribe();
        this.brokenFeedsCountUnsubscribe();
        this.clearAutoRefresh();
    }

    updateBrokenFeeds = (feeds) => {
        if (feeds.length) this.hidden = false;
        else this.hidden = true;

        const feedEls = feeds.map(feed => {
            const feedEl = document.createElement('flux-broken-feed');

            feedEl.feedid = feed.id;
            feedEl.title = feed.title;
            feedEl.source = feed.parsing_error_message;
            feedEl.date = feed.checked_at;

            return feedEl;
        });
        this.$('#feeds').replaceChildren(...feedEls);
    };

    #processing = false;

    refreshAll = () => {
        if (this.#processing) return;

        this.#processing = true;

        const succededfeedEls = this.$$('flux-broken-feed[refreshstatus="success"]');
        succededfeedEls.forEach(el => el.hidden = true);

        const feedEls = this.$$('flux-broken-feed:not([refreshstatus="success"]):not([refreshstatus="progress"])');
        const limit = parseInt(this.$('[name="limit"]').value);
        const feeds = [...feedEls].slice(0, limit);

        this.refreshAllEl.spinIcon = true;

        Promise.all(feeds.map(el => el.refreshFeed()))
            .finally(() => {
                this.#processing = false;

                this.refreshAllEl.spinIcon = false;
            });
    };

    #autoRefreshRef = null;

    toggleAutoRefresh = () => {
        if (this.#autoRefreshRef) this.clearAutoRefresh();
        else this.setAutoRefresh();
    };

    setAutoRefresh() {
        if (this.#autoRefreshRef) return;

        this.refreshAllEl.click();

        this.#autoRefreshRef = setInterval(() => {
            this.refreshAllEl.click();
        }, 2000);

        this.autoRefreshEl.setAttribute('icon', 'mdi:restart-off');
    }

    clearAutoRefresh() {
        if (!this.#autoRefreshRef) return;
        
        clearInterval(this.#autoRefreshRef);

        this.#autoRefreshRef = null;

        this.autoRefreshEl.setAttribute('icon', 'mdi:restart');
    }
}

BrokenFeedsManager.define();