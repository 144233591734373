import { WebComponent, css, html } from '../lib/web-component.js';
import { mediaQueries, surface } from './tokens.js';

export class MenuItem extends WebComponent {
    static is = 'menu-item';

    static styles = css`
        :host {
            display: block;
        }

        .Button {
            color: inherit;
            display: grid;
            grid-template-columns: 3rem 1fr auto;
            grid-template-rows: 4rem;
            align-items: center;
            gap: 2rem;
            border: none;
            padding: 1rem 2rem;
            inline-size: 100%;
            background: transparent;
            text-align: start;
            font-size: 2rem;
            outline: none;
            cursor: pointer;
            transition: background .1s, filter .1s;
        }

        .Button:hover,
        .Button:focus {
            ${surface.tinted1}
        }

        .Button:active {
            filter: brightness(0.7);
        }

        .Label {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .Counter {}

        :host([hide-icon]) .Icon {
            display: none;
        }

        :host([hide-icon]) .Button {
            grid-template-columns: 1fr auto;
        }

        :host([selected]) .Button {
            background: #7775;
        }

        @media ${mediaQueries.onDesktop} {
            :host {
                margin: 1rem;
            }

            .Button {
                grid-template-rows: 3rem;
                border-radius: 1rem;
                padding: 1rem;
            }
        }
    `;

    static template = html`
        <button class="Button">
            <div class="Icon">
                <slot name="icon">
                    <ui-icon icon="mdi:rss"></ui-icon>
                </slot>
            </div>
            <div class="Label">
                <slot></slot>
            </div>
            <div class="Counter">
                <slot name="counter"></slot>
            </div>
        </button>
    `;

    get selected() {
        return this.hasAttribute('selected');
    }

    set selected(value) {
        return this.toggleAttribute('selected', value);
    }
}

MenuItem.define();

