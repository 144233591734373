import { callAPI } from '../lib/api.js';

export const login = (auth) => callAPI('GET', auth, '/me');

export const getCategories = (auth) => callAPI('GET', auth, '/categories');

export const getFeeds = (auth) => callAPI('GET', auth, '/feeds');

export const discoverFeeds = (auth, url, data) => callAPI('POST', auth, '/discover', {}, { ...data, url });

export const addFeed = (auth, url, data) => callAPI('POST', auth, '/feeds', {}, { ...data, feed_url: url });

export const getCounters = (auth) => callAPI('GET', auth, '/feeds/counters');

export const refreshFeed = (auth, feedId) => callAPI('PUT', auth, `/feeds/${feedId}/refresh`, {}, {});

export const getEntries = (auth, params) => callAPI('GET', auth, '/entries', params);

export const getEntry = (auth, id) => callAPI('GET', auth, `/entries/${id}`);

export const getEntryOriginalContent = (auth, id) => callAPI('GET', auth, `/entries/${id}/fetch-content`);

export const toggleBookmark = (auth, id) => callAPI('PUT', auth, `/entries/${id}/bookmark`, {}, {});

export const toggleReadStatus = (auth, id, status) => callAPI('PUT', auth, '/entries', {}, { entry_ids: [id], status });

