import { accountState } from '../core/state.js';
import { WebComponent, css, html } from '../lib/web-component.js';
import { mediaQueries, size, surface } from './tokens.js';

export class AppLayout extends WebComponent {
    static is = 'app-layout';

    static styles = css`
        :host {
            ${surface.surface1}
            display: grid;
            grid-template:
                "drawer header" auto
                "drawer main  " 1fr
                / auto 1fr;
            block-size: 100vh;
            block-size: 100dvh;
        }

        .Header {
            ${surface.surface3}
            grid-area: header;
            display: flex;
            align-items: center;
            padding-inline: 1rem;
        }

        .Header-bar {
            flex: 1;
            margin-inline-start: 1rem;
        }

        .Drawer {
            ${surface.surface4}
            grid-area: drawer;
            z-index: 10;
            transition: transform .25s ease-out;
            overflow-y: auto;
        }

        .Drawer-scrim {
            grid-area: drawer-scrim;
            background: #0003;
            z-index: 5;
            transition: opacity .25s ease-out;
        }

        .Main {
            ${surface.surface1}
            grid-area: main;
            padding-block: 2rem;
            overflow-y: auto;
            overflow-x: hidden;
            overflow-x: clip;
        }

        @media ${mediaQueries.onMobile} {
            :host {
                grid-template-areas:
                    "header header"
                    "main   main  ";
                grid-template-rows:
                    [drawer-start drawer-scrim-start]
                        auto
                        1fr
                    [drawer-end drawer-scrim-end];
                grid-template-columns:
                    [drawer-start drawer-scrim-start]
                        min(100vw, ${size.drawer})
                    [drawer-end]
                        1fr
                    [drawer-scrim-end];
            }

            :host([bottom-bar]) {
                grid-template-areas:
                    "main   main  "
                    "header header";
                grid-template-rows:
                    [drawer-start drawer-scrim-start]
                        1fr
                        auto
                    [drawer-end drawer-scrim-end];
            }

            .Drawer,
            .Drawer-scrim {
                transform: translateX(-100%);
            }

            .Drawer-scrim {
                opacity: 0;
            }

            :host([open]) .Drawer,
            :host([open]) .Drawer-scrim {
                transform: translateX(0);
            }

            :host([open]) .Drawer-scrim {
                opacity: 1;
            }
        }

        @media ${mediaQueries.onDesktop} {
            .Header-trigger,
            .Drawer-scrim {
                display: none;
            }

            .Drawer {
                resize: inline;
                inline-size: ${size.drawer};
            }
        }
    `;

    static template = html`
        <nav class="Drawer">
            <slot name="drawer"></slot>
        </nav>
        <div class="Drawer-scrim"></div>

        <header class="Header">
            <div class="Header-trigger">
                <icon-button icon="mdi:menu"></icon-button>
            </div>
            <div class="Header-bar">
                <slot name="header"></slot>
            </div>
        </header>

        <main class="Main">
            <slot></slot>
        </main>
    `;

    get open() {
        return this.hasAttribute('open');
    }

    set open(value) {
        this.toggleAttribute('open', value);
    }

    get bottomBar() {
        return this.hasAttribute('bottom-bar');
    }

    set bottomBar(value) {
        this.toggleAttribute('bottom-bar', value);
    }

    firstRendered() {
        this.$('.Drawer-scrim').addEventListener('click', this.#toggleDrawer);
        this.$('icon-button').addEventListener('click', this.#toggleDrawer);

        this.$('.Main').addEventListener('dblclick', this.toggleFullScreen);
    }

    #toggleDrawer = () => {
        this.open = !this.open;
    };

    toggleFullScreen = () => {
        if (!accountState.activeToken.value) return;

        if (!document.fullscreenElement) {
            this.$('.Main').requestFullscreen().catch((err) => {
                alert(
                    `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
                );
            });
        } else {
            document.exitFullscreen();
        }

    };
}

AppLayout.define();