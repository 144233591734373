import { CoreComponent } from './component.js';

export class SubAccount extends CoreComponent {
    static is = 'sub-account';

	static observedAttributes = ['username'];

    static template = this.html`
        <menu-folder trigger-reverse>
            <menu-item slot="folder" class="switch">
                <ui-icon slot="icon" icon="mdi:account"></ui-icon>
                <span class="username"></span>
            </menu-item>

            <menu-item class="switch">
                <ui-icon slot="icon" icon="mdi:login-variant"></ui-icon>
                <span>Switch account</span>
            </menu-item>

            <dialog-panel id="dialog">
                <menu-item slot="trigger">
                    <ui-icon slot="icon" icon="mdi:trash"></ui-icon>
                    <span>Remove account</span>
                </menu-item>

                <span slot="title">Remove account</span>

                <p>Do you want to remove this account?</p>
                <p><strong class="username"></strong></p>

                <icon-button id="delete-account" slot="footer" icon="mdi:trash" with-label>Delete</icon-button>
                <icon-button id="cancel-delete" slot="footer" icon="mdi:cancel" with-label>Cancel</icon-button>
            </dialog-panel>
        </menu-folder>
    `;

	firstRendered() {
		this.updateUsername();

		this.$$('.switch').forEach(el => el.addEventListener('click', () => {
			this.fireEvent('switch-account', this.token);
		}));

		this.$('#cancel-delete').addEventListener('click', () => {
			this.$('#dialog').close();
		});

		this.$('#delete-account').addEventListener('click', () => {
			this.fireEvent('delete-account', this.token);
		});
	}

	get username() {
		return this.getAttribute('username');
	}

	set username(value) {
		this.setAttribute('username', value);

		this.updateUsername();
	}

	updateUsername() {
		for (const el of this.$$('.username')) {
			el.innerText = this.username;
		}
	}

	get token() {
		return this.getAttribute('token');
	}

	set token(value) {
		this.setAttribute('token', value);
	}

	attributeChangedCallback(_attr, oldValue, newValue) {
		if (newValue === oldValue) return;

		this.updateUsername();
	}
}

SubAccount.define();