import { WebComponent, css, html } from '../lib/web-component.js';
import { commonStyles } from '../ui/styles.js';

export class CoreComponent extends WebComponent {
    static prefix = 'flux';

    static html = html;
    static css = css;

    static define(tag = null) {
        const componentName = `${this.prefix}-${tag || this.is}`;
        customElements.define(componentName, this);
    }

    static get styles() {
        return [
            super.styles,
            ...commonStyles,
        ];
    }

    fireEvent(eventName, detail={}) {
        this.dispatchEvent(
            new CustomEvent(eventName, {
                detail,
                bubbles: true,
                composed: true,
            })
        );
    }
}