export const parseContent = content => {
    const parser = new DOMParser();

    const parsedContent = parser.parseFromString(content, 'text/html').body;

    return parsedContent;
};

export const parseSettings = text => {
    const re = /^@pocketflux\/(?<name>\w*)\s+(?<json>.+)\s*$/gm;
    const typeSettings = 'settings';
    const typeRewrite = 'rewrite';

    const rules = {};

    for (const match of text.matchAll(re)) {
        const { name, json } = match.groups;
        try {
            const rule = JSON.parse(json);
            rules[name] = [...(rules[name] || []), rule];

        } catch (e) {
            console.error(e);
        }
    }

    const settings = (rules[typeSettings] || [])
        .reduce((options, rule) => ({ ...options, ...rule, }), {});

    const rewriteRules = Object.fromEntries((rules[typeRewrite] || []));

    return [ settings, rewriteRules ];
};
