import { CoreComponent } from './component.js';
import { accountState, appState, feedState, settingsState, viewState } from './state.js';

export class Drawer extends CoreComponent {
    static is = 'drawer';

    static template = this.html`
        <menu-drawer>
            <flux-account slot="header"></flux-account>

            <h3>Feeds</h3>

            <a href="#/" tabindex="-1">
                <menu-item data-route="unread">
                    <ui-icon slot="icon" icon="mdi:inbox"></ui-icon>
                    <span>Unread</span>
                    <span id="unread" slot="counter">0</span>
                </menu-item>
            </a>

            <a href="#/starred" tabindex="-1">
                <menu-item data-route="starred">
                    <ui-icon slot="icon" icon="mdi:star"></ui-icon>
                    <span>Starred</span>
                </menu-item>
            </a>

            <a href="#/history" tabindex="-1">
                <menu-item data-route="history">
                    <ui-icon slot="icon" icon="mdi:history"></ui-icon>
                    <span>History</span>
                </menu-item>
            </a>

            <flux-new-feed></flux-new-feed>

            <h3>Categories</h3>

            <div id="categories"></div>

            <flux-install-trigger slot="footer">
                <menu-item>
                    <ui-icon slot="icon" icon="mdi:progress-download"></ui-icon>
                    <span class="feed-name">Install app</span>
                </menu-item>
            </flux-install-trigger>

            <flux-broken-feeds-manager slot="footer"></flux-broken-feeds-manager>

            <flux-settings slot="footer"></flux-settings>

            <menu-item id="logout" class="hide-unlogged" slot="footer">
                <ui-icon slot="icon" icon="mdi:logout-variant"></ui-icon>
                <span>Logout</span>
            </menu-item>
        </menu-drawer>
    `;

    static loadingTemplate = this.html`
        <menu-item>
            <ui-icon spin-icon slot="icon" icon="mdi:loading"></ui-icon>
            <span>Loading feeds</span>
        </menu-item>
    `;

    static categoryTemplate = this.html`
        <menu-folder>
            <a class="category-link" slot="folder" tabindex="-1">
                <menu-item data-route="categories">
                    <span slot="icon"></span>
                    <span class="category-name">Category</span>
                    <span class="category-counter" slot="counter">0</span>
                </menu-item>
            </a>

            <div class="feeds"></div>
        </menu-folder>
    `;

    static feedTemplate = this.html`
        <a class="feed-link" tabindex="-1">
            <menu-item data-route="feeds">
                <ui-icon slot="icon" class="feed-icon" icon="mdi:rss"></ui-icon>
                <span class="feed-name">Feed</span>
                <span class="feed-counter" slot="counter">0</span>
            </menu-item>
        </a>
    `;

    firstRendered() {
        this.categoriesEl = this.$('#categories');
        this.unreadEl = this.$('#unread');

        this.unreadUnsubscribe = feedState.globalUnread.subscribe(
            total => this.unreadEl.innerText = total
        );

        this.categoryFeedsUnsubscribe = feedState.categoryFeeds.subscribe(
            this.updateCategories
        );

        this.activeAccountUnsubscribe = accountState.activeAccount.subscribe(
            this.refreshFeeds
        );

        this.routeSelectorUnsubscribe = viewState.routeSelector.subscribe(
            selector => {
                this.$$('[data-route]').forEach(el => el.selected = false);
                this.$$(selector).forEach(el => el.selected = true);
                this.$$(`menu-folder:has(${selector}) [data-route="categories"]`)
                    .forEach(el => {
                        el.selected = true
                    });

                if (settingsState.autoOpenFolder.value) {
                    this.$$(`menu-folder:has(${selector})`)
                        .forEach(el => {
                            el.open = true;
                        });
                }
            }
        );

        this.addEventListener('refresh-feeds', this.refreshFeeds);

        this.$('#logout').addEventListener('click', () => appState.logout());
    }

    disconnectedCallback() {
        this.unreadUnsubscribe();
        this.categoryFeedsUnsubscribe();
        this.activeAccountUnsubscribe();
        this.routeSelectorUnsubscribe();
    }

    refreshFeeds = (account) => {
        if (!account) {
            this.categoriesEl.replaceChildren();
            this.$$('.hide-unlogged').forEach(el => el.hidden = true);
            return;
        };

        this.$$('.hide-unlogged').forEach(el => el.hidden = false);

        const loadingEl = this.constructor.loadingTemplate.clone();
        this.categoriesEl.replaceChildren(loadingEl);

        appState.fetchFeeds(account);
    };

    updateCounter({ feedId, categoryId, read }) {
        const increment = read ? -1 : +1;

        const categoryEl = this.$(`[categoryid="${categoryId}"]`);
        const feedEl = this.$(`[feedid="${feedId}"]`);

        this.unreadEl.innerText = +this.unreadEl.innerText + increment;
        categoryEl.textContent = +categoryEl.textContent + increment;
        feedEl.textContent = +feedEl.textContent + increment;
    }

    updateCategories = (categories) => {
        if (!categories.length) return;

        const categoriesEls = categories.map(this.createCategoryEl);
        this.categoriesEl.replaceChildren(...categoriesEls);
    };

    createCategoryEl = ({ id, title, unread, feeds }) => {
        const categoryEl = this.constructor.categoryTemplate.clone();

        categoryEl.querySelector('menu-item').dataset.id = id;
        categoryEl.querySelector('.category-link').href = `#/categories/${id}`;
        categoryEl.querySelector('.category-name').innerText = title;
        categoryEl.querySelector('.category-counter').innerText = unread;
        categoryEl.querySelector('.category-counter').setAttribute('categoryid', id);

        const feedEls = feeds.map(this.createFeedEl);
        categoryEl.querySelector('.feeds').replaceChildren(...feedEls);

        return categoryEl;
    };

    createFeedEl = ({ id, title, unread, parsing_error_count, disabled }) => {
        const feedEl = this.constructor.feedTemplate.clone();

        feedEl.querySelector('menu-item').dataset.id = id;
        feedEl.querySelector('.feed-link').href = `#/feeds/${id}`;
        feedEl.querySelector('.feed-name').innerText = title;
        feedEl.querySelector('.feed-counter').innerText = unread;
        feedEl.querySelector('.feed-counter').setAttribute('feedid', id);

        if (parsing_error_count) {
            feedEl.querySelector('.feed-icon').classList.add('error');
            feedEl.querySelector('.feed-icon').setAttribute('icon', 'mdi:rss-off');
        }

        if (disabled) {
            feedEl.querySelector('.feed-icon').setAttribute('icon', 'mdi:rss-off');
        }

        return feedEl;
    };
}

Drawer.define();