import { CoreComponent } from './component.js';
import { accountState, appState } from './state.js';

export class Account extends CoreComponent {
    static is = 'account';

    static template = this.html`
        <menu-folder id="account" trigger-reverse>
            <ui-icon slot="trigger" icon="mdi:exchange"></ui-icon>

            <menu-item slot="folder">
                <ui-icon slot="icon" icon="mdi:account"></ui-icon>
                <span id="account-username"></span>
            </menu-item>

            <div id="sub-accounts"></div>

            <flux-login>
                <menu-item>
                    <ui-icon slot="icon" icon="mdi:add"></ui-icon>
                    <span>Add account</span>
                </menu-item>

                <span slot="title">Add account</span>
            </flux-login>
        </menu-folder>

        <flux-login id="login-trigger">
            <menu-item>
                <ui-icon slot="icon" icon="mdi:login-variant"></ui-icon>
                <span>Login</span>
            </menu-item>

            <span slot="title">Login</span>
        </flux-login>
    `;

    firstRendered() {
        this.activeAccountUnsubscribe = accountState.activeAccount.subscribe(this.updateAccount);
        this.updateSubAccountsUnsubscribe = accountState.subAccounts.subscribe(this.updateSubAccounts);

        this.addEventListener('switch-account', ({ detail: token }) => {
            appState.switchAccount(token);
        });

        this.addEventListener('delete-account', ({ detail: token }) => {
            appState.removeAccount(token);
        });
    }

    disconnectedCallback() {
        this.activeAccountUnsubscribe();
        this.updateSubAccountsUnsubscribe();
    }

    updateAccount = (account) => {
        if (account) {
            this.$('#login-trigger').hidden = true;
            this.$('#account').hidden = false;

            this.$('#account-username').innerText = account.username;

            this.$('#account').open = false;

        } else {
            this.$('#login-trigger').hidden = false;
            this.$('#account').hidden = true;
        }
    };

    updateSubAccounts = (subAccounts=[]) => {
        const subAccountEls = subAccounts.map(({ username, token }) => {
            const subAccountEl = document.createElement('flux-sub-account');

            subAccountEl.username = username;
            subAccountEl.token = token;

            return subAccountEl;
        });
        this.$('#sub-accounts').replaceChildren(...subAccountEls);
    };
}

Account.define();