import { CoreComponent } from './component.js';
import { entryState } from './state.js';

export class PostActions extends CoreComponent {
    static is = 'post-actions';

    static template = this.html`
        <app-actions>
            <a id="prev">
                <icon-button icon="mdi:chevron-left"></icon-button>
            <a>
            <a id="next">
                <icon-button icon="mdi:chevron-right"></icon-button>
            <a>
        </app-actions>
    `;

    firstRendered() {
        this.prevEl = this.$('#prev');
        this.nextEl = this.$('#next');

        this.prevEntryUnsubscribe = entryState.prevEntry.subscribe(
            entry => this.updateLink(this.prevEl, entry || {})
        );

        this.nextEntryUnsubscribe = entryState.nextEntry.subscribe(
            entry => this.updateLink(this.nextEl, entry || {})
        );
    }

    disconnectedCallback() {
        this.prevEntryUnsubscribe();
        this.nextEntryUnsubscribe();
    }

    updateLink(el, { id, title }) {
        if (id) {
            el.href = `#/post/${id}`;
            el.title = title;
            el.toggleAttribute('disabled', false);
        } else {
            el.href = '';
            el.title = '';
            el.toggleAttribute('disabled', true);
        }
    }
}

PostActions.define();