import { WebComponent, css, html } from '../lib/web-component.js';
import { appTheme } from './tokens.js';

export class MenuDrawer extends WebComponent {
    static is = 'menu-drawer';

    static styles = css`
        :host {
            display: grid;
            grid-template-rows: auto 1fr auto;
            gap: 2rem;
            block-size: 100%;
        }

        .Header {
            padding-block-start: 2rem;
        }

        .Footer {
            padding-block-end: 2rem;
        }

        ::slotted(hr) {
            margin-block: 1rem;
            border: none;
            block-size: .1rem;
            background: ${appTheme.tinted1};
        }

        ::slotted(h3) {
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 1;
            margin: 2rem 2.3rem .5rem;
            text-transform: uppercase;
        }
    `;

    static template = html`
        <div class="Header">
            <slot name="header"></slot>
        </div>
        <div class="Main">
            <slot></slot>
        </div>
        <div class="Footer">
            <slot name="footer"></slot>
        </div>
    `;
}

MenuDrawer.define();
