import { CoreComponent } from './component.js';
import { appState } from './state.js';

export class Login extends CoreComponent {
    static is = 'login';

    static template = this.html`
        <dialog-panel id="dialog">
            <slot slot="trigger"></slot>

            <slot slot="title" name="title"></slot>

            <form id="login" method="dialog">
                <label>
                    <span>
                        <ui-icon icon="mdi:internet"></ui-icon>
                        <span>
                            Server URL
                        </span>
                    </span>
                    <input type="url" name="server-url" placeholder="URL" required />
                </label>

                <label>
                    <span>
                        <ui-icon icon="mdi:cloud-key"></ui-icon>
                        <span>
                            API Key
                        </span>
                    </span>
                    <input type="password" name="api-key" placeholder="API Key" required />
                </label>

                <input type="submit" hidden />

                <div class="footer">
                    <form-icon-button id="submit" icon="mdi:login-variant" with-label>Login</form-icon-button>
                    <div id="error-message"></div>
                </div>
            </form>
        </dialog-panel>
    `;

    firstRendered() {
        this.dialogEl = this.$('#dialog');
        this.formEl = this.$('#login');
        this.submitButtonEl = this.$('#submit');
        this.errorMessageEl = this.$('#error-message');

        this.formEl.addEventListener('submit', this.onSubmit);
    }

    onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        const data = {
            url: new URL(formData.get('server-url')).origin,
            token: formData.get('api-key'),
        };

        this.setToLoading();
        this.errorMessageEl.innerText = '';

        appState.login(data)
            .then(() => {
                this.dialogEl.close();
            })
            .catch(err => {
                this.errorMessageEl.innerText = err;
            })
            .finally(() => {
                this.setToNormal();
            });
    };

    setToLoading() {
        this.submitButtonEl.icon = 'mdi:loading';
        this.submitButtonEl.spinIcon = true;
    }

    setToNormal() {
        this.submitButtonEl.icon = 'mdi:login-variant';
        this.submitButtonEl.spinIcon = false;
    }
}

Login.define();