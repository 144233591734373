export const config = {
    baseUnit: '8px',
    baseFontSize: '2.5rem',

    theme: {
        light: {
            brand: '#2f9e44',

            surface1: '#f8f9fa',
            surface2: '#dee2e6',
            surface3: '#ced4da',
            surface4: '#e9ecef',

            tinted1: '#49505733',
            tinted2: '#868e9622',

            font1: '#3a3a37',
            font2: '#50514f',

            link: '#2f9e44',
            visited: '#7950f2',

            error: '#e03131',
        },
        dark: {
            brand: '#69db7c',

            surface1: '#030507',
            surface2: '#16191d',
            surface3: '#212529',
            surface4: '#343a40',

            tinted1: '#adb5bd44',
            tinted2: '#ced4da22',

            font1: '#dee2e6',
            font2: '#adb5bd',

            link: '#69db7c',
            visited: '#9775fa',

            error: '#e03131',
        },
    },
};

export const fontSize = {
};

export const lineHeight = {
};

export const weight = {
};

export const elevation = {
};

export const appTheme = {
    brand: 'var(--brand)',

    surface1: 'var(--surface-1)',
    surface2: 'var(--surface-2)',
    surface3: 'var(--surface-3)',
    surface4: 'var(--surface-4)',

    tinted1: 'var(--tinted-1)',
    tinted2: 'var(--tinted-2)',

    font1: 'var(--font-1)',
    font2: 'var(--font-2)',

    link: 'var(--link)',
    visited: 'var(--visited)',

    error: 'var(--error)',
};

export const surface = {
    surface1: `
        background: ${appTheme.surface1};
        color: ${appTheme.font1};
    `,
    surface2: `
        background: ${appTheme.surface2};
        color: ${appTheme.font1};
    `,
    surface3: `
        background: ${appTheme.surface3};
        color: ${appTheme.font2};
    `,
    surface4: `
        background: ${appTheme.surface4};
        color: ${appTheme.font2};
    `,
    tinted1: `
        background: ${appTheme.tinted1};
        color: ${appTheme.font2};
    `,
    tinted2: `
        background: ${appTheme.tinted2};
        color: ${appTheme.font2};
    `,
}

export const size = {
    // sizepx: '1px',
    // size0: '.1rem',
    // size1: '.2rem',
    // size03: '.3rem',
    // size05: '.5rem',

    // size0: '1rem',
    // size1: '1rem',
    // size2: '2rem',
    // size3: '3rem',
    // size4: '4rem',
    // size5: '5rem',
    // size6: '6rem',
    // size7: '7rem',

    header: '7rem',
    container: '120rem',
    drawer: '36rem',
    breakpoint: '40rem',

    /*
    .1rem
    .5rem
    1rem
    1.5rem
    1.8rem
    2rem
    2.3rem
    2.5rem
    3rem
    4rem
    5rem
    7rem
    8rem
    */
};

export const radius = {
    round: '50%',
};

export const mediaQueries = {
    onMobile: `(max-width: ${size.breakpoint})`,
    onDesktop: `(min-width: ${size.breakpoint})`,
    darkMode: '(prefers-color-scheme: dark)',
    mousePointer: '(hover: hover)',
    touchPointer: '(hover: none)',
};

